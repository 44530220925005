const Tabs = ["Profile", "Social Links", "Change Password"];
const TabsEnums = {
  profile: "Profile",
  links: "Social Links",
  password: "Change Password",
};
const SocialTabs = ["Profile", "Social Links"];
const SocialTabsEnums = {
  profile: "Profile",
  links: "Social Links",
};

export { Tabs, TabsEnums, SocialTabs, SocialTabsEnums };
