const modals = {
  terms: {
    name: "SignIn",
  },
  approval: {
    name: "SignUp",
  },
  winner: {
    name: "OTP",
  },
  success: {
    name: "Reset Password",
  },
};

export { modals };
