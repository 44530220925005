import classNames from "classnames";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router";
import CustomButton from "shared/components/customButton";
import { routeConstant } from "shared/routes/routeConstant";
import styles from "./style.module.scss";

function Example({
  show,
  handleClose,
  item,
  isChallenge = true,
  showDetails = true,
}) {
  const navigate = useNavigate();
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton className={styles.header}>
          {isChallenge ? <Modal.Title>Challenge</Modal.Title> : ""}
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: item }}></div>
        </Modal.Body>
        <Modal.Footer className={styles.footer}>
          <div style={{ display: "flex", width: "100%" }}>
            {showDetails ? (
              <CustomButton
                label="Details"
                style2
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(routeConstant.art.path.replace(":id", item?.id));
                }}
                customBtnContainer={classNames(
                  styles.btnContainer,
                  styles.route,
                  "gap-1 gap-sm-0"
                )}
                customIconStyle={classNames(styles.btnIcon)}
              />
            ) : null}

            <CustomButton
              label="Close"
              style2
              onClick={(e) => {
                handleClose();
              }}
              customBtnContainer={classNames(
                styles.btnContainer,
                "gap-1 gap-sm-0"
              )}
              customIconStyle={classNames(styles.btnIcon)}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Example;
